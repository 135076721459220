import UserPermission from '../const/userPermission';

const navigation = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.DASHBOARD
  },
  {
    name: 'Lojas',
    url: '/lojas',
    icon: 'fa fa-shopping-cart',
    badge: {
      variant: 'info'
    },
    attributes: { rel: 'noopener' },
    children: [
      {
        name: 'Cadastro do revendedor',
        url: '/lojas',
        permission: UserPermission.PERMISSIONS.STORES_VIEW
      },
      {
        name: 'Autorização QR Code',
        url: '/storeUpload',
        permission: UserPermission.PERMISSIONS.STORES_VIEW
      },
      {
        name: 'Relatório de Performance',
        url: '/performance',
        permission: UserPermission.PERMISSIONS.STORES_VIEW
      },
      {
        name: 'Adicionar nota fiscal',
        url: '/lojas/upload/invoice',
        permission: UserPermission.PERMISSIONS.EAI_BO_FINANCIAL_UPLOAD_NF
      },
    ]
  },
  {
    name: 'Agenda',
    url: '/agenda',
    icon: 'fa fa-clock-o',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS_SCHEDULE
  },
  {
    name: 'Usuários',
    url: '/usuarios',
    icon: 'fa fa-users',
    attributes: { rel: 'noopener' },
    children: [
      {
        name: 'Usuários Backoffice',
        url: '/usuarios/backoffice',
        permission: UserPermission.PERMISSIONS.USERS
      },
      {
        name: 'Usuários Portal',
        url: '/usuarios/portal',
        permission: UserPermission.PERMISSIONS.USERS_STORE
      }
    ]
  },
  {
    name: 'Categorias',
    url: '/categorias',
    icon: 'fa fa-file',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.CATEGORIES
  },
  {
    name: 'Permissões',
    url: '/permissoes',
    icon: 'fa fa-lock',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.PERMISSIONS
  },
  {
    name: 'Grupos',
    url: '/grupos',
    icon: 'fa fa-cogs',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.GROUPS
  },
  {
    name: 'Meios de Pagamentos',
    url: '/meios-pagamentos',
    icon: 'fa fa-credit-card',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.PAYMENT_METHODS
  },
  {
    name: 'Bancos',
    url: '/bancos',
    icon: 'fa fa-bank',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.BANKS
  },
  {
    name: 'Optin',
    url: '/optin',
    icon: 'fa fa-align-center',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.OPTIN
  },

  {
    name: 'Clientes',
    url: '/clientes',
    icon: 'fa fa-users',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.CUSTOMERS
  },
  {
    name: 'Prevenção a fraude',
    icon: 'fa fa-shield',
    attributes: { rel: 'noopener' },
    permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION,
    children: [
      {
        name: 'Trusted',
        url: '/trusted',
        permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_TRUSTED
      },
      {
        name: 'Blocklist Clientes',
        url: '/blocklist/clientes',
        permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_BLOCKLIST_PF
      },
      {
        name: 'Blocklist Lojas',
        url: '/blocklist/lojas',
        permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_BLOCKLIST_PJ
      },
      {
        name: 'Biometria Facial',
        url: '/biometria-facial',
        permission: UserPermission.PERMISSIONS.EAI_BO_FACIAL_BIOMETRICS
      }
    ]
  },
  {
    name: 'Pedidos',
    url: '/pedidos',
    icon: 'fa fa-shopping-basket',
    children: [
      {
        name: 'Geral',
        url: '/pedidos',
        permission: UserPermission.PERMISSIONS.ORDERS
      },
      {
        name: 'PIX',
        url: '/pix',
        permission: UserPermission.PERMISSIONS.ORDERS
      }
    ]
  },
  {
    name: 'Campanhas',
    icon: 'fa fa-balance-scale',
    attributes: { rel: 'noopener' },
    permission: UserPermission.PERMISSIONS.EAI_BO_CAMPAIGN,
    children: [
      {
        name: 'Benefícios',
        url: '/beneficios',
        permission: UserPermission.PERMISSIONS.STORES_CREATE_EDIT_BENEFITS
      },
      {
        name: 'Lista de ações',
        url: '/lista-de-acoes',
        permission: UserPermission.PERMISSIONS.EAI_BO_ACTIONS_LIST
      },
      {
        name: 'Gestão de Campanhas',
        url: '/gestor-de-campanhas',
        permission: UserPermission.PERMISSIONS.EAI_BO_ACTIONS_LIST
      },
    ]
  },
  {
    name: 'Financeiro',
    icon: 'fa fa-balance-scale',
    attributes: { rel: 'noopener' },
    permission: UserPermission.PERMISSIONS.ORDERS,
    children: [
      {
        name: 'Gerar Nota Fiscal',
        url: '/notas',
        permission: UserPermission.PERMISSIONS.ORDERS
      },
      {
        name: 'Vouchers',
        url: '/vouchers',
        permission: UserPermission.PERMISSIONS.ORDERS
      },
      {
        name: 'Lançamento Contábil',
        url: '/lancamento-contabil',
        permission: UserPermission.PERMISSIONS.ORDERS
      },
      {
        name: 'CNAB',
        url: '/cnab',
        permission: UserPermission.PERMISSIONS.CNAB
      },      
      {
        name: 'Repasses',
        url: '/repasses',
        attributes: { rel: 'noopener' },
        permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
        children: [
          {
            name: 'Repasse sintético',
            url: '/repasses/sintetico',
            permission: UserPermission.PERMISSIONS.STORES_PAYMENTS_RESUME
          },
          {
            name: 'Repasse analítico',
            url: '/repasses'
          }
        ]
      }
    ]
  },
  {
    name: 'Relatórios',
    url: '/relatorios',
    icon: 'fa fa-file-text',
    badge: {
      variant: 'info'
    },
    permission: UserPermission.PERMISSIONS.REPORTS
  }
];

export default navigation;
